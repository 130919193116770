<template>
  <div class="auth-widget--min-height flex flex-col gap-10 md:pt-10">
    <UiDialogTitle class="text-2xl font-semibold">{{
      $t("authentication.common.signup_with_email")
    }}</UiDialogTitle>

    <AuthManager is-signup :loading="signupMutation.isPending.value" @onSubmit="submit" />

    <Teleport to="#auth-widget-nav-slot" :disabled="!isMobile">
      <UiButton
        class="md:text-primary group h-fit max-h-6 w-fit p-0 text-base/6 font-medium text-black/50"
        variant="link"
        @click="changeAuthPage('signup')"
      >
        <LucideChevronLeft
          class="transition-all group-hover:-translate-x-1 rtl:rotate-180 rtl:group-hover:translate-x-1"
          :size="20"
        />
        {{ t("back_to_signup") }}
      </UiButton>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query"
import { HTTPError } from "ky"
import { LucideChevronLeft } from "lucide-vue-next"

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })

const gtm = useGtm()
const popup = usePopup()
const { isMobile } = useDisplay()

const apiError = useAuthApiError()
const { changeAuthPage } = useLogin()
const { formState } = useAuthManager()
const { executeReCaptcha } = useReCaptchaHandler()

const handleApiErrorMessages = (errorCode: number) => {
  let authApiError = { code: errorCode }

  switch (errorCode) {
    case 409:
    default:
      Object.assign(authApiError, {
        message: "authentication.apiErrors.general",
      })
      break
  }

  apiError.error.value = authApiError as AuthApiError
}

const handleApiErrorPopups = (errorCode: number) => {
  switch (errorCode) {
    case 406:
      // If errorCode is Not Acceptable (406) it means the user is signed up but not verified
      // so we redirect to the verify page
      return changeAuthPage("verify")

    case 409:
      return null

    case 429:
      return popup.open({
        title: t("authentication.blocked.header_title"),
        content: t("authentication.apiErrors.too_many_tries"),
      })

    case 500:
      return popup.open({ title: t("authentication.apiErrors.general") })
  }
}

function handleSignupApiSuccess(user: any) {
  gtm.push({ event: "set_user_data", user_id: user.id, email: formState.email })
  changeAuthPage("verify")
}

function handleSignupApiError(error: HTTPError) {
  if (!error?.response || !(error instanceof HTTPError)) return

  const code = error.response.status

  handleApiErrorMessages(code)
  handleApiErrorPopups(code)
}

const signupWithRecaptcha = async (credentials: SignupCredentials) => {
  const recaptchaToken = await executeReCaptcha(RecaptchaAction.SIGNUP)
  return AuthService.signupWithRecaptcha(credentials, recaptchaToken)
}

const signupMutation = useMutation({
  mutationFn: signupWithRecaptcha,
  onSuccess: handleSignupApiSuccess,
  onError: handleSignupApiError,
})

const submit = async (form: { email: string; password: string; firstName: string; lastName: string }) => {
  gtm.push({ event: "signup_attempt", method: "user-password", email: form.email })

  signupMutation.mutate(form)
}
</script>

<i18n lang="json">
{
  "he": {
    "back_to_signup": "חזרה לכל אפשרויות ההרשמה"
  },
  "en": {
    "back_to_signup": "All sign up options"
  }
}
</i18n>
